import React, { useState } from "react";

import Video from "../../wrappervideo/Videobg.jsx";

import ContactFixed from "../../contact/components/maincontactbtn.jsx";
import { Box } from "@mui/material";

import FixedImage from "../../../assets/images/PESSOAS2030_BarraCofinan_Sem_Desig_White.svg";
export default function Section1() {
  
  return (
    <>
      <Box sx={{
          position: "fixed",
          bottom: "0px",
          right: "0",
          borderRadius: " 4px ",
          background: "rgba(255,255,255,0.16)",
          zIndex: "1000",
          //background: "#FFFFFF 0% 0% no-repeat padding-box",
          borderRadius: "24px 0px 0px 0px",
          padding:"10px 10px 10px 10px",
          backdropFilter: "blur(30px)"//,
          //-webkit-backdrop-filter: "blur(30px)"
        }}>
          <img src={FixedImage} alt="" />
      </Box>


      <Video />
      <Box
        sx={{
          position: "fixed",
          top: "140px",
          right: "0",
          borderRadius: " 4px ",
          background: "rgba(255,255,255,0.16)",
          zIndex: "2000",
        }}
      >
        <ContactFixed />
      </Box>
    </>
  );
}
